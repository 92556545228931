



























































































































































import LazyHydrate from 'vue-lazy-hydration';
import {
  ref,
  computed,
  defineComponent,
  PropType,
  useRouter,
} from '@nuxtjs/composition-api';
import {
  SfRating,
  SfImage,
  SfButton,
  SfHeading,
  SfSelect,
  SfDivider,
  SfPagination,
} from '@storefront-ui/vue';
import moment from 'moment';
import type { Pagination } from '~/composables/types';
import { Product } from '~/modules/catalog/product/types';
import { useImage, useProduct, useUiHelpers } from "~/composables";
import {
  getReviewAuthor,
  getReviewDate,
  getReviewMessage,
  getReviewRating,
  getTotalReviews,
  getItems,
} from '~/modules/review/getters/reviewGetters';
import SkeletonLoader from "~/components/SkeletonLoader/index.vue";
import FtRating from "~/components/customSFUI/vue/src/components/atoms/FtRating/FtRating.vue";

export default defineComponent({
  name: 'FortytwoProductReviewDetail',
  components: {
    SfRating,
    SfImage,
    SfButton,
    SfHeading,
    SfSelect,
    SfDivider,
    SfPagination,
    LazyHydrate,
    SkeletonLoader,
    FtRating,
  },
  props: {
    product: {
      type: [Object, null] as PropType<Product>,
      default: null,
    },
    reviews: {
      type: Object,
      default: null,
    },
    reviewScore: {
      type: Object,
    },
    loading: Boolean,
  },
  setup(props) {
    const router = useRouter();
    const th = useUiHelpers();
    const { getProductPath } = useProduct();
    const { imageSizes: { productCard: imageSize } } = useImage();
    const totalReviews = computed(() => getTotalReviews(props.product));
    const reviewTotalScore = computed(() => props.reviewScore?.review_total_score ?? 0);
    // Review Histogram
    const reviewChart = ref([]);
    reviewChart.value[0] = computed(() => props.reviewScore?.review_chart_score?.one ?? 0);
    reviewChart.value[1] = computed(() => props.reviewScore?.review_chart_score?.two ?? 0);
    reviewChart.value[2] = computed(() => props.reviewScore?.review_chart_score?.three ?? 0);
    reviewChart.value[3] = computed(() => props.reviewScore?.review_chart_score?.four ?? 0);
    reviewChart.value[4] = computed(() => props.reviewScore?.review_chart_score?.five ?? 0);

    const reviewItems =  computed(() => getItems(props.reviews));
    const pagination = computed(() => getPagination(props.reviews));
    
    const getPagination = (reviewData): Pagination => ({
      currentPage: reviewData?.reviews?.page_info?.current_page || 1,
      totalPages: reviewData?.reviews?.page_info?.total_pages || 1,
      totalItems: reviewData?.items_count || 0,
      itemsPerPage: reviewData?.reviews?.page_info?.page_size || 10,
      pageOptions: [10, 50, 100],
    });

    const changeItemsPerPage = async (itemsPerPage: number, forcePush = true): Promise<void> => {
      const query = {
        ...th.getFiltersDataFromUrl(false),
        itemsPerPage: itemsPerPage.toString(10),
        page: '1',
      };

      if (forcePush) {
        await router.push({ query });
      } else {
        const routeData = router.resolve({ query });
        window.history.pushState({}, null, routeData.href);
      }
    };

    const modifyName = (fullName) => {
      // Split the name into parts
      const nameParts = fullName.split(" ");

      // Initialize an empty array to store the modified name parts
      let modifiedNameParts = [];

      // Loop through each part of the name
      for (let i = 0; i < nameParts.length; i++) {
        if (i === 0) {
          // Keep the first name as it is
          modifiedNameParts.push(nameParts[i]);
        } else {
          // Add the first letter with a dot for middle and last names
          modifiedNameParts.push(nameParts[i].charAt(0) + ".");
        }
      }

      // Join the modified name parts into the final string
      const modifiedName = modifiedNameParts.join(" ");
      return modifiedName;
    };

    return {
      moment,
      imageSize,
      getProductPath,
      getReviewAuthor,
      getReviewDate,
      getReviewMessage,
      getReviewRating,
      totalReviews,
      reviewTotalScore,
      reviewChart,
      reviewItems,
      pagination,
      changeItemsPerPage,
      modifyName,
    };

  }

});
